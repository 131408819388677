<template>
    <nl-loader v-if="preloading" :show="true" />
    <div v-else>
        <div class="mb-3">
            <router-link to="/admin/spots/sites" class="text-decoration-none">
                <b-button type="is-light" size="is-small" icon-left="backward"> Retourner aux spots</b-button>
            </router-link>
        </div>
        <div class="box">
            <NlTable
                :columns.sync="columns"
                :get-data="getOrders"
                :force-update="forceUpdate"
                checkable
                :left-buttons="buttons"
                :checked-rows.sync="checkedRows"
            >
                <template #status="{ props: { row } }">
                    <span v-if="row.status === 'todo'" class="tag is-info">À faire</span>
                    <span
                        v-else-if="['unassigned', 'unpublished', 'in_progress', 'reviewed'].includes(row.status)"
                        class="tag is-warning"
                    >
                        En cours
                    </span>
                    <span v-else-if="row.status === 'completed'" class="tag is-success">Fait</span>
                </template>

                <template #buyer="{ props: { row } }">
                    {{ row.buyer.user }}
                </template>

                <template #meta_title="{ props: { row } }">
                    <span v-if="row.meta_title !== null">{{ row.meta_title }}</span>
                    <b-input
                        v-else
                        size="is-small"
                        @keyup.enter.native="updateOrder('meta', row.id, $event.target.value)"
                    />
                </template>

                <template #tag_title="{ props: { row } }">
                    <span v-if="row.tag_title !== null">{{ row.tag_title }}</span>
                    <b-input
                        v-else
                        size="is-small"
                        @keyup.enter.native="updateOrder('tag', row.id, $event.target.value)"
                    />
                </template>

                <template #price_paid="{ props: { row } }"> {{ row.price_paid / 100 }} € </template>
            </NlTable>
        </div>
    </div>
</template>

<script>
    import NlTable from '@/components/UI/Table/NlTable.vue'
    import { mapActions } from 'vuex'

    export default {
        name: 'SpotsSites',
        components: { NlTable },
        title: 'Commandes par spots',
        data() {
            return {
                groups: null,
                preloading: false,
                forceUpdate: false,
                checkedRows: [],
                buttons: [
                    {
                        label: 'Envoyer en rédaction',
                        icon: 'check',
                        type: 'is-warning',
                        action: this.redactor,
                        disabled: () => this.checkedRows.length === 0
                    }
                ],
                columns: [
                    {
                        field: 'id',
                        searchable: false,
                        sortable: false,
                        label: 'ID'
                    },
                    {
                        field: 'status',
                        custom: true,
                        searchable: false,
                        sortable: false,
                        label: 'Statut'
                    },
                    {
                        field: 'anchor',
                        searchable: false,
                        sortable: false,
                        label: 'Ancre'
                    },
                    {
                        field: 'meta_title',
                        custom: true,
                        searchable: false,
                        sortable: false,
                        label: 'Meta Title'
                    },
                    {
                        field: 'tag_title',
                        custom: true,
                        searchable: false,
                        sortable: false,
                        label: 'Tag Title'
                    },
                    {
                        field: 'url_source',
                        searchable: false,
                        sortable: false,
                        label: 'URL'
                    },
                    {
                        field: 'url_target',
                        searchable: false,
                        sortable: false,
                        label: 'Page'
                    },
                    {
                        custom: true,
                        field: 'buyer',
                        searchable: false,
                        sortable: false,
                        label: 'Client'
                    },
                    {
                        searchable: false,
                        custom: true,
                        sortable: false,
                        field: 'price_paid',
                        label: 'Prix'
                    }
                ]
            }
        },
        created() {
            this.$store.commit('spots/setSpotId', this.$route.params.id)
            this.getGroups().then(response => {
                this.groups = response.groups
            })
        },
        methods: {
            ...mapActions('spots', {
                getOrders: 'listOrdersBySpot',
                addTitle: 'addTitle',
                sendToRedactor: 'sendToRedactor'
            }),
            ...mapActions('group', {
                getGroups: 'adminList'
            }),
            findGroupName(id) {
                let group = this.groups.find(group => group.id === id)
                return group ? group.name : ''
            },
            formatWPMeta(meta) {
                if (!meta) {
                    return null
                } else {
                    return JSON.parse(meta)
                }
            },
            updateOrder(type, id, value) {
                let params = {
                    id: id
                }
                if (type === 'meta') {
                    params.meta_title = value
                } else if (type === 'tag') {
                    params.tag_title = value
                }
                this.addTitle(params)
                    .then(() => {
                        this.forceUpdate = !this.forceUpdate
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: 'Le titre a bien été modifié'
                        })
                    })
                    .catch(() => {
                        this.$store.dispatch('toast', {
                            type: 'error',
                            title: 'Une erreur est survenue'
                        })
                    })
            },
            redactor() {
                let params = {
                    ids: this.checkedRows.map(row => row.id),
                    action: 'redactor'
                }
                this.sendToRedactor(params)
                    .then(() => {
                        this.forceUpdate = !this.forceUpdate
                        this.$store.dispatch('toast', {
                            type: 'success',
                            title: 'Les commandes ont bien été envoyées en rédaction'
                        })
                    })
                    .catch(() => {
                        this.$store.dispatch('toast', {
                            type: 'error',
                            title: 'Une erreur est survenue'
                        })
                    })
            }
        }
    }
</script>

<style scoped></style>
